import React, { useState, useEffect } from 'react';
import ExportBtn from '../../components/ExportBtn';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

import API from '../../api/analytics.js';
import Loader from '../../components/Loader/index.js';

import './analytics.scss';
import translations from '../../translations/de.js';
import InputSeason from '../../components/InputSeason/InputSeason.js';
const ShowRate = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState(null);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            try {
                const res = await API.getScannedTickets({ params: { season_id: selectedSeason } });
                setData(
                    res.data.map((entry) => {
                        return {
                            percentage: entry.visit_percentage,
                            scanned_count: entry.scanned_count,
                            visit_count: entry.visit_count,
                        };
                    }),
                );

                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        };
        selectedSeason && getData();
    }, [selectedSeason]);

    const CustomTooltip = ({ payload }) => {
        if (!payload || !payload[0]?.payload) return null;

        const { scanned_count, percentage } = payload[0].payload;

        let tooltipContent;
        if (isNaN(percentage)) {
            if (scanned_count === 0) {
                tooltipContent = <p>{translations.no_tickets_activated}</p>;
            } else {
                tooltipContent = <p>{`${scanned_count} ${translations.tickets_not_activated}`}</p>;
            }
        } else if (percentage === 0) {
            tooltipContent = <p>{`${scanned_count} ${translations.scanned_not_visited}`}</p>;
        } else {
            tooltipContent = (
                <p>
                    {`${scanned_count} ${translations.scanned_tickets_1} ${percentage ?? 0}${
                        translations.scanned_tickets_2
                    }`}
                </p>
            );
        }

        return <div className="tooltip-wrapper scanned-tickets">{tooltipContent}</div>;
    };

    const formatPercent = (value) => (!isNaN(value) ? `${value}%` : value);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            {' '}
            <div style={{ width: '200px' }}></div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginLeft: '2.5%',
                    marginRight: '2.5%',
                    right: '0',
                    zIndex: '10',
                }}
            >
                <div style={{ width: '200px' }}>
                    <InputSeason
                        selectedSeason={selectedSeason}
                        setSelectedSeason={setSelectedSeason}
                    />
                </div>
                <div style={{ marginTop: '14px' }}>
                    <ExportBtn
                        label="Showrate.csv"
                        fileName="showrate"
                        apiFunc={() => API.exportTicketHolders()}
                    />
                </div>
            </div>
            <div
                style={{
                    width: '700px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'visible',
                }}
            >
                {loading ? (
                    <div className="loader-wrapper">
                        <Loader theme="dark" size="big" />
                    </div>
                ) : (
                    <>
                        {data.length > 0 ? (
                            <BarChart
                                width={900}
                                height={650}
                                data={data}
                                cursor={'pointer'}
                                margin={{
                                    top: 150,
                                    right: 80,
                                    left: 80,
                                    bottom: 150,
                                }}
                            >
                                <CartesianGrid strokeDasharray="0" />

                                <XAxis
                                    dataKey="percentage"
                                    tickFormatter={formatPercent}
                                    tick={{
                                        fontSize: 12,
                                    }}
                                    label={{
                                        value: translations.scanned_tickets_xaxis,
                                        position: 'insideBottom',
                                        offset: -20,
                                        zIndex: 100,
                                    }}
                                />
                                <YAxis
                                    tick={{
                                        fontSize: 14,
                                    }}
                                    label={{
                                        value: translations.scanned_ticket_yaxis,
                                        position: 'top',
                                        offset: '30',
                                    }}
                                />

                                <Tooltip position="top" content={CustomTooltip} />
                                <Bar
                                    dataKey="scanned_count"
                                    fill="#4472c4"
                                    barSize={60}
                                    interval="50"
                                />
                            </BarChart>
                        ) : (
                            <div className="piechart-placeholder">
                                No statistics data for passes.
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ShowRate;
