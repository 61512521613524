import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import Button from '../../components/Button';
import ExportBtn from '../../components/ExportBtn';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import translations from '../../translations/de';
import Pagination from './../../components/Pagination';
import formatName from '../../helpers/formatName';
import Select from '../../components/Select';
import Input from './../../components/Input';
import debounce from 'lodash.debounce';
import './FanGames.scss';
import { Link } from 'react-router-dom';
import { FaLongArrowAltRight } from 'react-icons/fa';
import Loader from '../../components/Loader';
import getFanGameTabName from '../../helpers/getFanGameTabName';
import API from '../../api/games';
import TabSwitcher from '../../components/TabSwitcher';
import FanGameRewardTable from '../../components/FanGameRewardTable/FanGameRewardTable';
import InputSeason from '../../components/InputSeason/InputSeason';

const FanGames = ({ store, tab }) => {
    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState(store.searchQuery);
    const [gameStatus, setGameStatus] = useState('any');
    const [selectedSeason, setSelectedSeason] = useState(null);

    useEffect(() => {
        var currTab = tab !== 'all' ? tab : undefined;
        selectedSeason && store.getFanGames(searchQuery, gameStatus, currTab, selectedSeason);
    }, [store, store.selectedPage, searchQuery, gameStatus, tab, selectedSeason]);

    const handleFanGame = (id) => {
        store.getFanGame(id).then(() => {
            sessionStorage.setItem('activeFanGameTab', getFanGameTabName(tab));
            store?.selectedGame && history.push(`/fan-games/${store.selectedGame.id}`);
        });
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 500);
    return (
        <div style={{ width: '100%' }}>
            <div className="tab-controls">
                <Select
                    label="GAME STATUS"
                    defaultValue={{ label: 'Any', value: 'any' }}
                    options={[
                        { label: 'Any', value: 'any' },
                        { label: 'Active Games', value: '!archived&published' },
                        { label: 'Archived Games', value: 'archived' },
                        { label: 'Started Games', value: 'started' },
                        { label: 'Ended Games', value: 'ended' },
                        { label: 'Published Games', value: 'published' },
                    ]}
                    onChange={(e) => {
                        store.setSelectedPage(1);
                        setGameStatus(e.value);
                    }}
                />
                <Input
                    label="GAME TITLE"
                    placeholder={translations.search}
                    onChange={onSearchChangeHandler}
                    defaultValue={searchQuery}
                />
                <InputSeason
                    selectedSeason={selectedSeason}
                    setSelectedSeason={setSelectedSeason}
                />

                <ExportBtn
                    label={translations.export_fan_games}
                    fileName="fan-games"
                    style={{ position: 'relative', right: '0', marginBottom: '15px' }}
                    apiFunc={() => API.exportFanGames()}
                />
            </div>
            <TabSwitcher initialTabName={translations.fan_games} switcherKey="childTab1">
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName={translations.fan_games} />
                    {tab !== 'all' && <TabSwitcher.TabPill tabName={translations.voucher} />}
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName={translations.fan_games}>
                    {store.fan_games && store.fan_games.length !== 0 ? (
                        store.dataLoaded ? (
                            <Table
                                tablePagination={
                                    store.totalPages > 1 && (
                                        <Pagination
                                            totalPages={store.totalPages}
                                            selectedPage={store.selectedPage}
                                            onSelectedPageChange={(selected) =>
                                                store.setSelectedPage(selected)
                                            }
                                        />
                                    )
                                }
                                tableName={translations.fan_games}
                                columns={[
                                    {
                                        Header: 'ID',
                                        accessor: 'id',
                                        Cell: ({ value }) => <div>{value}</div>,
                                    },
                                    {
                                        Header: translations.fan_game_type,
                                        accessor: 'fan_game_type',
                                        Cell: ({ value }) => <div>{value}</div>,
                                    },
                                    {
                                        Header: translations.name,
                                        accessor: 'name',
                                        Cell: ({ value }) => <div>{value}</div>,
                                    },
                                    {
                                        Header: translations.description,
                                        accessor: 'description',
                                        Cell: ({ value }) => (
                                            <div>
                                                <span className="text">{value}</span>
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: translations.popup_title,
                                        accessor: 'popup_title',
                                        Cell: ({ value }) => <div>{value}</div>,
                                    },
                                    {
                                        Header: translations.game_id,
                                        accessor: 'game_id',
                                        Cell: ({ value }) => (
                                            <div>
                                                {value && (
                                                    <Link to={`/matches/${value}`}>
                                                        <FaLongArrowAltRight />
                                                        {value}
                                                    </Link>
                                                )}
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: '',
                                        accessor: 'action',
                                        Cell: ({ row }) => (
                                            <div>
                                                <Button
                                                    onClick={() => {
                                                        handleFanGame(row.cells[0].value);
                                                    }}
                                                    title={translations.show}
                                                >
                                                    <FaSearch />
                                                </Button>
                                            </div>
                                        ),
                                    },
                                ]}
                                data={store.fan_games.map(
                                    ({
                                        id,
                                        fan_game_type,
                                        name,
                                        description,
                                        popup_title,
                                        game_id,
                                    }) => {
                                        return {
                                            id,
                                            fan_game_type: formatName(fan_game_type),
                                            name,
                                            description,
                                            popup_title,
                                            game_id,
                                        };
                                    },
                                )}
                            />
                        ) : (
                            <Loader size="big" theme="dark" />
                        )
                    ) : (
                        <div className="no-data">No Data Yet...</div>
                    )}
                </TabSwitcher.TabPanel>
                {tab !== 'all' && (
                    <TabSwitcher.TabPanel activeWhenTabName={translations.voucher}>
                        <FanGameRewardTable activeTab={tab} selectedSeason={selectedSeason} />
                    </TabSwitcher.TabPanel>
                )}
            </TabSwitcher>
        </div>
    );
};

export default observer(FanGames);
