import React, { useState } from 'react';
import TabSwitcher from '../../components/TabSwitcher';
import FinalScoreGames from './FinalScoreGames';
import API from '../../api/users';
import { useHistory } from 'react-router-dom';
import FinalScoreUsers from './FinalScoreUsers';
import ExportBtn from '../../components/ExportBtn';
import translations from '../../translations/de';
import FanGameRewardTable from '../../components/FanGameRewardTable/FanGameRewardTable';
import InputSeason from '../../components/InputSeason/InputSeason';
import Select from '../../components/Select';
import Input from './../../components/Input';
import debounce from 'lodash.debounce';

const FinalScore = ({ store, tab }) => {
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    // filters
    const [season, setSeason] = useState(null);
    const [searchQuery, setSearchQuery] = useState();
    const [gameStatus, setGameStatus] = useState('any');

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
    }, 500);

    return (
        <div>
            <div className="tab-controls">
                <Select
                    label="GAME STATUS"
                    defaultValue={{ label: 'Any', value: 'any' }}
                    options={[
                        { label: 'Any', value: 'any' },
                        { label: 'Active Games', value: '!archived&published' },
                        { label: 'Archived Games', value: 'archived' },
                        { label: 'Started Games', value: 'started' },
                        { label: 'Ended Games', value: 'ended' },
                        { label: 'Published Games', value: 'published' },
                    ]}
                    onChange={(e) => {
                        store.setSelectedPage(1);
                        setGameStatus(e.value);
                    }}
                />
                <Input
                    label="GAME TITLE"
                    placeholder={translations.search}
                    onChange={onSearchChangeHandler}
                    defaultValue={searchQuery}
                />
                <InputSeason selectedSeason={season} setSelectedSeason={setSeason} />
            </div>

            <div className="btn_container">
                <ExportBtn
                    fileName="visana-participants"
                    label="list.csv"
                    apiFunc={() => API.downloadList('end_game_result')}
                />
                <ExportBtn
                    label="visana.csv"
                    fileName="visana-participants-sponsors"
                    apiFunc={() => API.downloadSponsorList('end_game_result')}
                />
            </div>
            <TabSwitcher
                initialTabName={
                    history?.location?.state?.activeSubTabName
                        ? history.location.state.activeSubTabName
                        : 'Games'
                }
            >
                <TabSwitcher.Tabs halfWidth={true}>
                    <TabSwitcher.TabPill tabName="Games" />
                    <TabSwitcher.TabPill tabName="All users" />
                    {tab !== 'all' && <TabSwitcher.TabPill tabName={translations.voucher} />}
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName="Games">
                    <FinalScoreGames
                        store={store}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        gameStatus={gameStatus}
                        season={season}
                        tab={tab}
                    />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="All users">
                    <FinalScoreUsers
                        users={users}
                        setUsers={setUsers}
                        selectedPage={selectedPage}
                        setTotalPages={setTotalPages}
                        setSelectedPage={(data) => setSelectedPage(data)}
                        totalPages={totalPages}
                    />
                </TabSwitcher.TabPanel>
                {
                    <TabSwitcher.TabPanel activeWhenTabName={translations.voucher}>
                        <FanGameRewardTable activeTab={tab} selectedSeason={season} />
                    </TabSwitcher.TabPanel>
                }
            </TabSwitcher>
        </div>
    );
};

export default FinalScore;
