import api from './apiLibrary';

const API = {
    getClaimedPasses(params) {
        return api.get('/api/admin/pass/claimed-passes', params);
    },
    getCreatedPasses(params) {
        return api.get('/api/admin/pass/created-passes', params);
    },
    getScannedTickets(params) {
        return api.get('/api/admin/pass/scanned-accesses', params);
    },
    exportTicketHolders() {
        return api.get('/api/admin/pass/claimed-passes/download');
    },
    getScannetTicketsPerTime(id) {
        return api.get(`/api/admin/pass/scanned-per-time/${id}`);
    },
};

export default API;
